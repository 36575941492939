import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';

import { userService } from '../services/user.services';
import { getUserId } from '../utils/cookies';

class UserStore {
    isLoading = false;
    errors = undefined;
    userData = null;
    isUserAuthorized = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
        const userId = getUserId()
        if(userId) {
            this.loadUserData(userId)
            this.checkIfUserIsAuthorized(userId)
        }
    }

    checkIfUserIsAuthorized(userId) {
        if(userId) {
            this.isUserAuthorized = true;
        } else {
            this.isUserAuthorized = false;
        }
    }

    async loadUserData(userId) {
        const savedData = this.getUser(userId);
        if (savedData) {
            this.userData = savedData;
        }
    }

    async getUser(userId) {
        this.isLoading = true;
        this.errors = undefined;

        try {
            const response = await userService.getUser(userId);
            this.userData = response.data
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }
}

export const UserStoreInstance = new UserStore();
export const UserStoreInstanceCTX = createContext(UserStoreInstance);

import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Link as LinkRouter, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Link,
  VStack,
  Text,
  Heading,
  Divider,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";

import TelegramAuth from '../../components/TelegramAuth';

import { AuthStoreInstanceCTX } from '../../stores/authStore';
import { UserStoreInstanceCTX } from '../../stores/userStore';
import { REGISTER_ROUTE, HOME_ROUTE } from "../../utils/consts";
import { getUserId } from '../../utils/cookies';


const Login = observer(() => {
  const handleNavigate = useNavigate();
  const {
    login, 
    setEmail, 
    setPassword,
    isLoading,
    errors,
  } = useContext(AuthStoreInstanceCTX);
  const { getUser, checkIfUserIsAuthorized } = useContext(UserStoreInstanceCTX);

  const formik = useFormik({
    initialValues: {
        email: '',
        password: '',
    },
    validationSchema: Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
        setEmail(values.email);
        setPassword(values.password);
        await login();
        await getUser(getUserId());
        await checkIfUserIsAuthorized(getUserId())
        resetForm();
        handleNavigate(HOME_ROUTE);
    },
  });

  return (
    <Box
      bg="gray.800"
      color="white"
      p={8}
      maxWidth="420px"
      minWidth={['320px', '420px']}
      mx="auto"
      mt={12}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Heading as="h1" mb={6} textAlign="center" fontSize="2xl">
        Вход
      </Heading>
      {/* <Button
        leftIcon={
          <img
            src="https://www.google.com/favicon.ico"
            alt="Google Icon"
            style={{ width: '20px', height: '20px' }}
          />
        }
        colorScheme="gray"
        variant="outline"
        width="full"
        mb={4}
      >
        Вход через аккаунт Google
      </Button>

      <Text textAlign="center" mb={4}>
        или
      </Text> */}

      <form 
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <VStack spacing={4}>
          <TelegramAuth />

          <FormControl id="email" isInvalid={formik.touched.email && formik.errors.email}>
            <FormLabel>Email</FormLabel>
            <Input 
              type="email" 
              placeholder="Email" 
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            ) : null}
          </FormControl>

          <FormControl id="password" isInvalid={formik.touched.password && formik.errors.password}>
            <FormLabel>Пароль</FormLabel>
            <Input 
              type="password" 
              placeholder="Пароль" 
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            ) : null}
          </FormControl>

          <Button colorScheme="blue" width="full" type="submit" isDisabled={formik.isSubmitting}>
            {formik.isSubmitting ? <Spinner size="sm" /> : 'Продолжить'}
          </Button>
          { errors ? (
              <Text color="red.500" mt={2}>{errors?.data?.message}</Text>
            ) : null}
        </VStack>
      </form>

      <Divider my={6} />

      <Text textAlign="center">
        Нет аккаунта?{' '}
        <LinkRouter as={Link} to={REGISTER_ROUTE} style={{ "textDecoration": "underline" }}>
          Создать аккаунт
        </LinkRouter>
      </Text>
    </Box>
  );
});

export default Login;

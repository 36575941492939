import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { authRouter, publicRouter } from "../routers";
import { COMING_SOON_ROUTE } from "../utils/consts";

import { UserStoreInstanceCTX } from "../stores/userStore";

const AppRouter = observer(() => {
  const { isUserAuthorized } = useContext(UserStoreInstanceCTX);

  return (
    <Routes>
      {isUserAuthorized && authRouter.map(({ path, Component }) =>
        <Route key={path} path={path} element={Component} />
      )}
      {publicRouter.map(({ path, Component }) =>
        <Route key={path} path={path} element={Component} />
      )}
      <Route
        path="/*"
        element={<Navigate to={COMING_SOON_ROUTE} />}
      />
    </Routes>
  )
});

export default AppRouter;
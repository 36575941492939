import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, Box, Flex } from "@chakra-ui/react";

import NavBar from "./components/NavBar";
import AppRouter from "./components/AppRouter";

const App = () => {
  return (
    <BrowserRouter>
      <ChakraProvider>
        <Flex direction="column" bg="gray.900" color="white" minHeight="100vh" height="100%">
          <NavBar />
          <Flex direction="column" alignItems="center" flex="1" padding="20px" height="100%">
            <Box mt={8} mb={8} textAlign="center" maxWidth={1500} height="100%">
              <AppRouter />
            </Box>
          </Flex>
        </Flex>
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default App;

import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Text, Image, Progress, Tooltip } from '@chakra-ui/react';
import { TiLockClosed } from 'react-icons/ti';

import ComingSoonImg from "../assets/coming-soon-1.jpg";

const ComingSoon = () => {
  const [buttonLeft, setButtonLeft] = useState('0px');
  const [imagePosition, setImagePosition] = useState({ left: 0, top: 0 });
  const [progress, setProgress] = useState(0);
  const imageRef = useRef(null);

  const startDate = new Date('2024-08-10T00:00:00');
  const targetDate = new Date('2025-01-10T00:00:00');

  const computeButtonPosition = () => {
    if (!imageRef.current) return

    const imageRect = imageRef.current.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const imagePositionPercentage = (imageRect.left + imageRect.width / 2) / screenWidth; // Center point of the image
    const computedPositionPixels = screenWidth * imagePositionPercentage;
    setButtonLeft(`calc(${computedPositionPixels}px - 23%)`);
  };

  useEffect(() => {
    computeButtonPosition();
    window.addEventListener('resize', computeButtonPosition);

    const updateProgress = () => {
      const now = new Date();
      const totalTime = targetDate - startDate;
      const elapsedTime = now - startDate;
      const progressPercentage = Math.min((elapsedTime / totalTime) * 100, 100);

      setProgress(progressPercentage);
    };

    updateProgress();
    const interval = setInterval(updateProgress, 1000);

    return () => {
      window.removeEventListener('resize', computeButtonPosition);
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      position="fixed"
      width="100%"
      height="calc(100vh)"
      zIndex="3"
      top="calc(72px + 90px)"
      left="0"
      right="0"
      bottom="0"
    >
      <Box position="absolute" top="-10%" left="50%" width="52%" zIndex="1" transform="translateX(-50%)">
        <Text color="white.700" fontSize="xl" fontWeight="bold" mb={2}>
          Прогресс разработки <strong>Fortune inc.</strong> - {Math.floor(progress)} %
        </Text>
        <Progress value={progress} size="sm" colorScheme="purple" bg="gray.700"/>
      </Box>

      <Box
         width="100%"
         height="100%"
         display="flex"
         alignItems="center"
         justifyContent="center"
      >

        <Image
          ref={imageRef}
          src={ComingSoonImg}
          alt="Background"
          // position="absolute"
          top="0"
          left="0"
          width="100vw"
          height="100%"
          objectFit="cover"
          // zIndex="-1"
        />
      </Box>
      <Button
        position="absolute"
        top="25%"
        // left={buttonLeft}
        left="5%"
        size="lg"
        bg="gray.700"
        _hover={{ bg: "gray.600" }} 
        color="#fff"
        zIndex="4"
      >
        Войти
      </Button>

      <Button
        position="absolute"
        top="25%"
        right="5%"
        size="lg"
        bg="gray.700"
        _hover={{ bg: "gray.600" }} 
        color="#fff"
        zIndex="4"
        
        sx={{
          _disabled: {
            opacity: 1,
            cursor: 'not-allowed',
            pointerEvents: 'none',
          },
        }}
      >
        Пригласить
        друга

        <Tooltip label="Castle Icon" hasArrow bg='gray.300' color='black' placement='auto'>
          <Box
            as={TiLockClosed}
            position="absolute"
            left="5px"
            top="-2px"
            transform="translateY(-50%)"
            color="white"
            boxSize={6}
          />
        </Tooltip>
      </Button>
    </Box>
  );
};

export default ComingSoon;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { axiosClassic } from "../api/interseptors";
import { LoginButton } from "@telegram-auth/react";
import TelegramLoginButton from 'react-telegram-login';

import { authService } from "../services/auth.services";
import { HOME_ROUTE } from "../utils/consts"

import { UserStoreInstanceCTX } from '../stores/userStore';
import { getUserId } from '../utils/cookies';



const TelegramAuth = ({ setIsAuthenticated }) => {
  const handleNavigate = useNavigate();
const { checkIfUserIsAuthorized } = useContext(UserStoreInstanceCTX);

  const handleTelegramResponse = async (data) => {
    console.log('handleTelegramResponse ', data)

    try {
      await authService.telegramAuth(data)
      await checkIfUserIsAuthorized(getUserId())
  
      handleNavigate(HOME_ROUTE)
    } catch(error) {
      console.error("Telegram login failed:", error);
    }
    
    // fetch(`${process.env.REACT_APP_API_URL}/api/auth/telegram/`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // })
      // .then((response) => response.json())
      // .then((data) => {
      //   if (data.status === "ok") {
      //     setIsAuthenticated(true); // just replace with actual
      //     window.location.href = "/";
      //   } else {
      //     console.error("Telegram login failed:", data.message);
      //   }
      // })
      // .catch((error) => {
      //   console.error("Telegram login error:", error);
      // });
  };

  return (
    <div className="telegram-auth-container">
      <TelegramLoginButton dataOnauth={handleTelegramResponse} botName={process.env.REACT_APP_TELEGRAM_BOT_USERNAME} />
      {/* <LoginButton
        botUsername={process.env.REACT_APP_TELEGRAM_BOT_USERNAME}
        onAuthCallback={handleTelegramResponse}
        buttonSize="large"
        cornerRadius={5}
        showAvatar={true}
        lang="en"
      /> */}
    </div>
  );
};

export default TelegramAuth;

import React, { useContext, useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { 
    Input, 
    InputGroup, 
    InputRightElement, 
    Button, 
    Text,
    Box,
    UnorderedList,
    ListItem,
    useToast
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import linkImg from '../../assets/link.svg'

import { ReferralStoreInstanceCTX } from '../../stores/referralStore'
import { REGISTER_ROUTE } from "../../utils/consts";
import { getUserId } from '../../utils/cookies'

import './styles.scss'


const ReferralLink = observer(() => {
    const [refInput, setRefInput] = useState('');
    const toast = useToast();
    const userId = getUserId()
    const { referralData, getReferralInfo, isLoading } = useContext(ReferralStoreInstanceCTX);

    const REFERRAL_LINK = `${process.env.REACT_APP_URL}${REGISTER_ROUTE}?referralCode=${referralData?.referralCode}`

    useEffect(() => {
        getReferralInfo(userId)
    }, [])

    useEffect(() => {
        setRefInput(REFERRAL_LINK)
    }, [referralData])


    const handleCopy = () => {
        navigator.clipboard.writeText(refInput)
            .then(() => {
                toast({
                    title: 'Скопировано!',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
            })
            .catch((err) => {
                toast({
                    title: 'Не удалось скопировать!',
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <div className="refLink__container">
            <Box textAlign="center" mb={8}>
                <Text fontSize="2xl" fontWeight="bold">
                    Станьте партнером и начинайте зарабатывать
                </Text>
                <Text fontSize="lg" color="gray.600">
                    Получайте бонусы за каждого приведенного друга
                </Text>
            </Box>
            {/* <div className="refLink__container__head">
                <div>
                    <h2 className="refLink__title">Станьте партнером и начинайте зарабатывать</h2>
                    <p className="refLink__subtitle">Получайте бонусы за каждого приведенного друга</p>
                </div>
                <Button variant="light">ПРАВИЛА</Button>
            </div> */}
            {/* <div className="refLink__container__balance">
                 <div>
                     <div className="refLink__container__balance__bText">
                         <p>Баланс</p>
                         <p>0 UAN</p>
                     </div>
                     <div className="refLink__container__balance__bText">
                         <p>Общая прибыль</p>
                         <p>0.000 UAN</p>
                     </div>
                 </div>
                 <div>
                     <div className="refLink__container__balance__bText">
                         <p>Доступно для вывода</p>
                         <p>0 UAN</p>
                     </div>

                     <Button colorScheme='yellow'>ВЫВЕСТИ</Button>
                 </div>
            </div>  */}
            <div className="refLink__container__content">
                <div className="refLink__container__content__howItWorks">
                    <Box p={4} textAlign="left">
                        <Text fontSize="l" fontWeight="bold" mb={4}>
                            Как это работает?
                        </Text>
                        <UnorderedList spacing={2} mb={4}>
                            <ListItem>Вы приглашаете своих друзей.</ListItem>
                            <ListItem>Друзья регистрируются, пополняют счет и делают ставки.</ListItem>
                            <ListItem>Вы получаете вознаграждение!</ListItem>
                        </UnorderedList>
                        <Text fontSize="md" color="gray.700">
                            Приводя друзей, вы выстраиваете многоуровневую сеть. 
                            С каждого уровня вы получаете прибыль в размере % от чистой прибыли компании <strong>Fortune Inc</strong>.
                        </Text>
                    </Box>
                </div>
                <div className="refLink__container__content__referral">
                    <img src={linkImg} width="50px" />

                    <h4>По ссылке</h4>
                    <p>
                        Скопируйте ссылку и разошлите её друзьям
                    </p>

                    <InputGroup mt={8}>
                        <Input
                            defaultValue={refInput}
                            contentEditable={false}
                            placeholder="Here will be your referral code"
                            pr="4.5rem"
                        />
                        <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={handleCopy} isLoading={isLoading}>
                                <FontAwesomeIcon icon={faCopy} />
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </div>
            </div>
        </div>
    )
})

export default ReferralLink

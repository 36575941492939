import { Link } from "react-router-dom";
import { Box, Flex, Text, Image, Grid, Progress, HStack, Icon } from "@chakra-ui/react";
import { FaGamepad, FaChartPie } from "react-icons/fa";
import {
  RUSSIAN_ROULETTE_ROUTE,
  BLAST_ROUTE,
} from "../utils/consts";
import RussianRouletteImg from "../assets/Games/Russ.jpg";
import BlastImg from "../assets/Games/Blast.jpeg";


const PlatformStat = ({ title, value }) => (
  <Flex bg="gray.800" borderRadius="md" p={6} w="100%" maxW="md" direction="column">
    <Text fontWeight="bold" mb={2}>{title}</Text>
    <Progress value={75} size="xs" colorScheme="lime" mb={4} />
    <Text fontSize="3xl" fontWeight="bold" color="lime">{value}</Text>
  </Flex>
);

const GameCard = ({ imageUrl, title, description, link }) => (
  <Link to={link ? link : "/"}>
    <Box bg="gray.700" borderRadius="md" overflow="hidden" height="100%">
      <Image src={imageUrl} alt={title} height="300px" width="100%" objectFit="cover" />
      <Box p={4}>
        <Text fontSize="xl" fontWeight="bold">{title}</Text>
        <Text mt={2}>{description}</Text>
      </Box>
    </Box>
  </Link>
);

const Home = () => {

  return (
    <Flex direction="column" bg="gray.900" color="white" minH="100vh">

      <Flex mb={5} align="center">
        <Icon as={FaGamepad} boxSize={5} mr={2} />
        <Text fontSize="1xl" fontWeight="bold" align='start'>ПОПУЛЯРНЫЕ ИГРЫ</Text>
      </Flex>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={8}>
        <GameCard
          imageUrl={RussianRouletteImg}
          title="Русская рулетка"
          description="Invaders must die."
          link={RUSSIAN_ROULETTE_ROUTE}
        />

        <GameCard
          imageUrl={BlastImg}
          title="Blast"
          description="Deposit crypto and spin the wheel. But remember: there can be only one winner."
          link={BLAST_ROUTE}
        />

        <GameCard
          imageUrl={RussianRouletteImg}
          title="MOON or DOOM"
          description="Think you know where crypto prices are headed in the next 60 seconds? Put your money where your mouth is."
        />
      </Grid>

      <Flex mb={5} align="center">
        <Icon as={FaChartPie} boxSize={5} mr={2} />
        <Text fontSize="1xl" fontWeight="bold" align='start'>Статистика</Text>
      </Flex>

      <HStack spacing={4} justifyContent="center">
        <PlatformStat title="Total Players" value="117 059" />
        <PlatformStat title="Total Game Volume" value="613 928" />
        <PlatformStat title="Total Games Played" value="29 017 744" />
      </HStack>
    </Flex>
  );
};

export default Home;

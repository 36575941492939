export const HOME_ROUTE = '/';
export const GAMES_ROUTE = '/games';
export const COMING_SOON_ROUTE = '/coming-soon';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const TASKS_ROUTE = '/tasks';
export const SHOP_ROUTE = '/shop';
export const PROFILE_ROUTE = '/profile';
export const BLAST_ROUTE = '/blast';
export const RUSSIAN_ROULETTE_ROUTE = '/russian-roulette';
export const REFERRAL_LINK = '/referral-link';
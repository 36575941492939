import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';

import { referralService } from '../services/referral.service';

class ReferralStore {
    isLoading = false;
    errors = undefined;
    referralData = null;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    async getReferralInfo(userId) {
        this.isLoading = true;
        this.errors = undefined;

        try {
            const { data } = await referralService.getReferralInfo(userId);
            this.referralData = data
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }
}

export const ReferralStoreInstance = new ReferralStore();
export const ReferralStoreInstanceCTX = createContext(ReferralStoreInstance);

import {
  ComingSoon,
  Home,
  BlastGame,
  // Login,
  // Register,
  RussianRoulette,
  ReferralLink,
} from "./pages/index";

import {
  HOME_ROUTE,
  COMING_SOON_ROUTE,
  TASKS_ROUTE,
  GAMES_ROUTE,
  BLAST_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  RUSSIAN_ROULETTE_ROUTE,
  REFERRAL_LINK,
} from './utils/consts';

import Login from './pages/Auth/Login'
import Register from './pages/Auth/Register'

export const authRouter = [
  { path: HOME_ROUTE, Component: <Home /> },
  { path: TASKS_ROUTE, Component: <Home /> },
  { path: GAMES_ROUTE, Component: <Home /> },
  { path: BLAST_ROUTE, Component: <BlastGame /> },
  { path: RUSSIAN_ROULETTE_ROUTE, Component: <RussianRoulette /> },
  { path: REFERRAL_LINK, Component: <ReferralLink /> }
];

export const publicRouter = [
  { path: COMING_SOON_ROUTE, Component: <ComingSoon /> },
  { path: LOGIN_ROUTE, Component: <Login /> },
  { path: REGISTER_ROUTE, Component: <Register /> },
];
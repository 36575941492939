import { Box, Text, Flex, Button, VStack, HStack, Avatar, CircularProgress, CircularProgressLabel } from "@chakra-ui/react";

const BlastGame = () => {
  return (
    <Flex direction="row" px={7} bg="gray.900" color="white" minH="100vh">

      <Box flex="1" bg="gray.800" borderRadius="lg" p={5} mr={5}>
        <Text fontSize="lg" fontWeight="bold" mb={4}>5 Players</Text>
        <VStack spacing={4} align="stretch">
          <PlayerCard name="foozbond_eth" points="3.8K" percent="90.48%" color="purple.400" />
          <PlayerCard name="eedC2D" points="100" percent="2.38%" color="green.400" />
          <PlayerCard name="A57388" points="500" percent="2.38%" color="blue.400" />
          <PlayerCard name="MercyKiller88" points="500" percent="2.38%" color="teal.400" />
          <PlayerCard name="2E17B5" points="500" percent="2.38%" color="yellow.400" />
        </VStack>
      </Box>

      <Box flex="2" bg="gray.800" borderRadius="lg" p={5} mr={5} textAlign="center">
        <Text fontSize="lg" fontWeight="bold" mb={4}>Current Round</Text>
        <CircularProgress value={42} size="200px" thickness="12px" color="purple.400" trackColor="gray.700">
          <CircularProgressLabel>
            <Text fontSize="2xl" fontWeight="bold">0,42</Text>
            <Text fontSize="md" color="lime.400">Drawing Winner...</Text>
          </CircularProgressLabel>
        </CircularProgress>
        <Box mt={8}>
          <Text fontSize="lg" fontWeight="bold">Round Contents</Text>
          <Box mt={4}>
            <Text fontSize="2xl" fontWeight="bold">0,42</Text>
            <Text fontSize="sm">ETH</Text>
          </Box>
        </Box>
      </Box>

      <Box flex="1" bg="gray.800" borderRadius="lg" p={5}>
        <Text fontSize="lg" fontWeight="bold" mb={4}>Round 130280</Text>
        <VStack spacing={3} align="stretch">
          <StatItem label="Prize Pool" value="0,42" />
          <StatItem label="Players" value="5/500" />
          <StatItem label="Your Entries" value="0" />
          <StatItem label="Your Win Chance" value="0%" />
        </VStack>
        <Button colorScheme="whiteAlpha" mt={8} w="full">
          Connect
        </Button>
      </Box>

    </Flex>
  );
};

const PlayerCard = ({ name, points, percent, color }) => (
  <Flex align="center" justify="space-between" p={3} bg="gray.700" borderRadius="md">
    <HStack>
      <Avatar size="sm" name={name} />
      <Box>
        <Text fontWeight="bold">{name}</Text>
        <Text fontSize="sm">{points} Pts</Text>
      </Box>
    </HStack>
    <Box textAlign="right">
      <Text fontSize="sm" color={color}>{percent}</Text>
    </Box>
  </Flex>
);

const StatItem = ({ label, value }) => (
  <HStack justify="space-between">
    <Text>{label}</Text>
    <Text fontWeight="bold">{value}</Text>
  </HStack>
);

export default BlastGame;

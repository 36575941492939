import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Box, HStack, IconButton, Spacer, Icon, Flex, Text, Button, Avatar, MenuItem, MenuList, MenuButton, Menu, Divider } from "@chakra-ui/react";
import { FaHome, FaGamepad, FaGift, FaCoins, FaTicketAlt, FaTrophy, FaCogs, FaTelegram, FaVolumeMute } from "react-icons/fa";

import {
  GAMES_ROUTE,
  HOME_ROUTE,
  TASKS_ROUTE,
  SHOP_ROUTE,
  PROFILE_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  REFERRAL_LINK,
} from "../utils/consts";

import { UserStoreInstanceCTX } from '../stores/userStore';
import { AuthStoreInstanceCTX } from '../stores/authStore';


const NavItem = ({ icon, label, link }) => (
  <Link to={link ? link : "/"} style={{ textDecoration: 'none', color: 'inherit' }}>
    <Flex
      align="center"
      _hover={{ bg: "gray.700" }}
      py={2}
      px={3}
      cursor="pointer"
    >
      <Icon as={icon} boxSize={5} mr={2} />
      <Text mb={0}>{label}</Text>
    </Flex>
  </Link>
);


const AvatarWithDropdown = ({ logoutHandler }) => (
  <Menu>
    <MenuButton as={Button} variant="link" cursor="pointer">
      <Link to={PROFILE_ROUTE} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Avatar name="John Doe" src="https://bit.ly/dan-abramov" />
      </Link>
    </MenuButton>
    <MenuList 
      bg="gray.700" 
      color="white" 
      border="1px"
      borderColor="gray.600"
      borderRadius="md"
      zIndex={5}
    >
      <MenuItem 
        bg="gray.800" 
        _hover={{ bg: 'gray.600' }} 
        mb={2}
        as={Link} 
        to={PROFILE_ROUTE}
      >
        Профиль
      </MenuItem>
      <MenuItem
        bg="gray.800" 
        _hover={{ bg: 'gray.600' }} 
        mb={2}
        as={Link} 
        to={REFERRAL_LINK}
      >
        Приглашай и зарабатывай
      </MenuItem>
      <MenuItem
        bg="gray.800" 
        _hover={{ bg: 'gray.600' }} 
        onClick={logoutHandler}
      >
        Выйти
      </MenuItem>
    </MenuList>
  </Menu>
)


const NavBar = observer(() => {
  const { isUserAuthorized } = useContext(UserStoreInstanceCTX);
  const { logout } = useContext(AuthStoreInstanceCTX);
  const handleNavigate = useNavigate()


  const logoutHandler = async () => {
    await logout();
    handleNavigate(LOGIN_ROUTE);
    window?.location?.reload()
  }

  return (
    <Flex as="nav" bg="gray.800" p={4} align="center" height="100px" maxHeight="72px">
      <Box fontSize="2xl" fontWeight="bold" mr={8}>
        Y
      </Box>

      {
        isUserAuthorized && (
          <HStack spacing={4}>
            <NavItem icon={FaHome} label="Главная" link={HOME_ROUTE} />
            <NavItem icon={FaGamepad} label="Игры" link={GAMES_ROUTE} />
            <NavItem icon={FaGift} label="Задания" link={TASKS_ROUTE} />
            <NavItem icon={FaTicketAlt} label="Магазин" link={SHOP_ROUTE} />
          </HStack>
        )
      }

      <Spacer />
      <IconButton
        icon={<FaTelegram />}
        aria-label="Close"
        bg="transparent"
        _hover={{ bg: "gray.700" }}
      />

      {
        isUserAuthorized ? (
          <>
            <Button ml={4} mr={4} bg="gray.700" _hover={{ bg: "gray.600" }} color="#fff">
              Пополнить
            </Button>

            <AvatarWithDropdown logoutHandler={logoutHandler} />
          </>
        ) : (
          <>
            <Button as={Link} to={LOGIN_ROUTE} ml={4} mr={4} bg="gray.700" _hover={{ bg: "gray.600" }} color="#fff">
              Вход
            </Button>
            
            <Box
              height="100%"
              // mx={4}
            >
              <Divider orientation="vertical" borderColor="gray.300" />
            </Box>
            <Button as={Link} to={REGISTER_ROUTE} ml={4} mr={4} bg="gray.700" _hover={{ bg: "gray.600" }} color="#fff">
              Регистрация
            </Button>
          </>
        )
      }

      {
        isUserAuthorized && (
          <IconButton
            ml={4}
            icon={<FaVolumeMute />}
            aria-label="Mute"
            bg="gray.700"
            _hover={{ bg: "gray.600" }}
          />
        )
      }
    </Flex>
  );
});

export default NavBar;
import { useContext, useEffect } from 'react';
import { Link as LinkRouter, useSearchParams, useNavigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Link,
  VStack,
  Text,
  Heading,
  Divider,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";

import { AuthStoreInstanceCTX } from '../../stores/authStore';
import { UserStoreInstanceCTX } from '../../stores/userStore';
import { LOGIN_ROUTE, HOME_ROUTE } from '../../utils/consts';
import { getUserId } from '../../utils/cookies';

import TelegramAuth from '../../components/TelegramAuth';

const Register = observer(() => {
  const handleNavigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { checkIfUserIsAuthorized } = useContext(UserStoreInstanceCTX);
  const {
    register, 
    setUsername, 
    setEmail, 
    setPassword,
    isLoading,
    errors,
  } = useContext(AuthStoreInstanceCTX);
  const referralCode = searchParams.get("referralCode")

  const formik = useFormik({
    initialValues: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    },
    validationSchema: Yup.object({
        username: Yup.string()
            .required('Username is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
        setUsername(values.username);
        setEmail(values.email);
        setPassword(values.password);
        await register(referralCode)
        await checkIfUserIsAuthorized(getUserId())
        resetForm()
        handleNavigate(HOME_ROUTE);
    },
  });

  return (
    <Box
      bg="gray.800"
      color="white"
      p={8}
      maxWidth="420px"
      minWidth={['320px', '420px']}
      mx="auto"
      mt={12}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Heading as="h1" mb={6} textAlign="center" fontSize="2xl">
        Регистрация
      </Heading>

      {/* <Button
        leftIcon={
          <img
            src="https://www.google.com/favicon.ico"
            alt="Google Icon"
            style={{ width: '20px', height: '20px' }}
          />
        }
        colorScheme="gray"
        variant="outline"
        width="full"
        mb={4}
      >
        Регистрация через аккаунт Google
      </Button>

      <Text textAlign="center" mb={4}>
        или
      </Text> */}

      <form 
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <VStack spacing={4}>
          <TelegramAuth />

          <FormControl id="username" isInvalid={formik.touched.username && formik.errors.username}>
            <FormLabel>Имя пользователя</FormLabel>
            <Input 
              type="text" 
              placeholder="Имя пользователя" 
              name="username"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
            {formik.touched.username && formik.errors.username ? (
              <FormErrorMessage>{formik.errors.username}</FormErrorMessage>
            ) : null}
          </FormControl>

          <FormControl id="email" isInvalid={formik.touched.email && formik.errors.email}>
            <FormLabel>Email</FormLabel>
            <Input 
              type="email" 
              placeholder="Email" 
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            ) : null}
          </FormControl>

          <FormControl id="password" isInvalid={formik.touched.password && formik.errors.password}>
            <FormLabel>Пароль</FormLabel>
            <Input 
              type="password" 
              placeholder="Пароль" 
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            ) : null}
          </FormControl>

          <FormControl id="confirm-password" isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}>
            <FormLabel>Подтвердите пароль</FormLabel>
            <Input 
              type="password" 
              placeholder="Подтвердите пароль" 
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
            ) : null}
          </FormControl>

          <Button 
            type="submit"
            colorScheme="blue" 
            width="full" 
            onSubmit={formik.handleSubmit} 
            isDisabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? <Spinner size="sm" /> : 'Зарегистрироваться'}
          </Button>
          { errors ? (
              <Text color="red.500" mt={2}>{errors?.data?.message}</Text>
            ) : null}
        </VStack>
      </form>

      <Divider my={6} />

      <Text textAlign="center">
        Уже есть аккаунт?{' '}
        <LinkRouter as={Link} to={LOGIN_ROUTE} style={{ "textDecoration": "underline" }}>
          Войти
        </LinkRouter>
      </Text>
    </Box>
  );
});

export default Register;
